import React from "react";
import { Container } from "react-bootstrap";
import { useIntl } from "gatsby-plugin-intl";

import { ExternalLink } from "../Button";

const DownloadButton = ({ fluid, title, file, fileName }) => {
  const intl = useIntl();

  return (
    <Container fluid={fluid} className="mb-5 pb-5">
      <h2 className="text-uppercase h5 mb-4">{title}</h2>
      <ExternalLink href={file} target="_blank" download={fileName || ""}>
        {intl.formatMessage({ id: "download" })}
      </ExternalLink>
    </Container>
  );
};

export default DownloadButton;
