import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { RichText } from "prismic-reactjs";

import { addGifToContent } from "../../utils";

const TextColumns = ({ fluid, columns }) => {
  const serializer = addGifToContent();
  return (
    <Container fluid={fluid} className="mb-5 pb-5">
      <Row>
        {columns.map((column, index) => (
          <Col key={`column-${index}`} lg={column.number_of_columns}>
            <RichText render={column.content.raw} htmlSerializer={serializer} />
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default TextColumns;
