import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useIntl, Link } from "gatsby-plugin-intl";
import { graphql } from "gatsby";
import { getImage, GatsbyImage } from "gatsby-plugin-image";
import styled from "styled-components";

import Layout from "../components/Layout";
import Button from "../components/Button";
import FullWidthImage from "../components/single-post/FullWidthImage";
import TextWithImage from "../components/single-post/TextWithImage";
import TextColumns from "../components/single-post/TextColumns";
import Content from "../components/single-post/Content";
import ImageColumns from "../components/single-post/ImageColumns";
import DownloadButton from "../components/single-post/DownloadButton";
import VideoBlock from "../components/VideoBlock";
import VirtualTour from "../components/VirtualTour";

const ReadingTime = styled.span`
  color: ${props => props.theme.colors.gray};
`;

const SinglePost = ({ data, pageContext }) => {
  const intl = useIntl();
  const {
    prismicBlog,
    previousPost: prevPostData,
    nextPost: nextPostData,
  } = data;
  const { prevPost, nextPost } = pageContext;
  console.log(prevPostData, nextPostData);

  return (
    <Layout>
      <Container className="pt-5 mt-5 mb-3">
        <Button to="/blog" className="pb-5 mb-5">
          {intl.formatMessage({ id: "back_to_blog" })}
        </Button>
        <h1 className="h3 text-uppercase">{prismicBlog?.data.title.text}.</h1>
        {prismicBlog?.data.reading_time && (
          <ReadingTime>
            {intl.formatMessage({ id: "reading_time" })}:{" "}
            {prismicBlog?.data.reading_time.text}
          </ReadingTime>
        )}
      </Container>
      {prismicBlog?.data.body.map(slice => {
        switch (slice.slice_type) {
          case "full_width_image":
            return (
              <FullWidthImage
                key={slice.id}
                image={slice.primary.image}
                fluid={slice.primary.fluid_container}
              />
            );
          case "text_and_image":
            return (
              <TextWithImage
                key={slice.id}
                fluid={slice.primary.container_fluid}
                switchCols={slice.primary.switch_columns}
                image={slice.primary.image}
                content={slice.primary.content.raw}
              />
            );
          case "text_columns":
            return (
              <TextColumns
                key={slice.id}
                fluid={slice.primary.container_fluid}
                columns={slice.items}
              />
            );
          case "content":
            return (
              <Content
                key={slice.id}
                fluid={slice.primary.container_fluid}
                content={slice.primary.content.raw}
              />
            );
          case "image_columns":
            return (
              <ImageColumns
                key={slice.id}
                fluid={slice.primary.container_fluid}
                columns={slice.items}
              />
            );
          case "download_button":
            return (
              <DownloadButton
                key={slice.id}
                fluid={slice.primary.container_fluid}
                title={slice.primary.title1.text}
                file={slice.primary.file.url}
                fileName={slice.primary.file.raw.name}
              />
            );
          case "video":
            return (
              <VideoBlock
                key={slice.id}
                fluid={slice.primary.container_fluid}
                video={slice.primary.video.embed_url}
              />
            );
          case "virtual_tour":
            return (
              <VirtualTour
                key={slice.id}
                fluid={slice.primary.container_fluid}
                url={slice.primary.tour_url.text}
              />
            );
          default:
            return null;
        }
      })}
      <Container fluid className="mt-5 pt-5">
        <Row className="mt-5">
          <Col md={6} className="mb-4 mb-md-0">
            {prevPost && (
              <Link to={`/blog/${prevPost}`} className="w-100">
                <Row className="align-items-center">
                  <Col lg={8}>
                    <GatsbyImage
                      image={getImage(
                        prevPostData?.data.cover.localFile.childImageSharp
                      )}
                      alt={prevPostData?.data.cover.alt || ""}
                    />
                  </Col>
                  <Col lg={4}>
                    <div className="text-uppercase text-dark">
                      {intl.formatMessage({ id: "previous" })}
                    </div>
                  </Col>
                </Row>
              </Link>
            )}
          </Col>
          <Col md={6}>
            {nextPost && (
              <Link to={`/blog/${nextPost}`} className="w-100">
                <Row className="align-items-center">
                  <Col lg={8}>
                    <GatsbyImage
                      image={getImage(
                        nextPostData?.data.cover.localFile.childImageSharp
                      )}
                      alt={nextPostData?.data.cover.alt || ""}
                    />
                  </Col>
                  <Col lg={4}>
                    <div className="text-uppercase text-dark">
                      {intl.formatMessage({ id: "next" })}
                    </div>
                  </Col>
                </Row>
              </Link>
            )}
          </Col>
        </Row>
      </Container>
    </Layout>
  );
};

export const query = graphql`
  query(
    $language: String!
    $uid: String!
    $prevPost: String
    $nextPost: String
  ) {
    prismicBlog(lang: { eq: $language }, uid: { eq: $uid }) {
      data {
        title {
          text
        }
        reading_time {
          text
        }
        body {
          ... on PrismicBlogBodyFullWidthImage {
            id
            slice_type
            primary {
              fluid_container
              image {
                alt
                localFile {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
            }
          }
          ... on PrismicBlogBodyTextAndImage {
            id
            slice_type
            primary {
              container_fluid
              switch_columns
              image {
                alt
                localFile {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
              content {
                raw
              }
            }
          }
          ... on PrismicBlogBodyTextColumns {
            id
            slice_type
            items {
              number_of_columns
              content {
                raw
              }
            }
            primary {
              container_fluid
            }
          }
          ... on PrismicBlogBodyContent {
            id
            slice_type
            primary {
              container_fluid
              content {
                raw
              }
            }
          }
          ... on PrismicBlogBodyImageColumns {
            id
            slice_type
            primary {
              container_fluid
            }
            items {
              number_of_columns
              image {
                alt
                localFile {
                  childImageSharp {
                    gatsbyImageData
                    id
                  }
                }
              }
            }
          }
          ... on PrismicBlogBodyDownloadButton {
            id
            slice_type
            primary {
              container_fluid
              file {
                url
                raw
              }
              title1 {
                text
              }
            }
          }
          ... on PrismicBlogBodyVideo {
            id
            slice_type
            primary {
              container_fluid
              video {
                embed_url
              }
            }
          }
          ... on PrismicBlogBodyVirtualTour {
            id
            slice_type
            primary {
              container_fluid
              tour_url {
                text
              }
            }
          }
        }
      }
    }
    nextPost: prismicBlog(lang: { eq: $language }, uid: { eq: $nextPost }) {
      data {
        cover {
          alt
          localFile {
            childImageSharp {
              gatsbyImageData(width: 500, height: 300)
            }
          }
        }
      }
    }
    previousPost: prismicBlog(lang: { eq: $language }, uid: { eq: $prevPost }) {
      data {
        cover {
          alt
          localFile {
            childImageSharp {
              gatsbyImageData(width: 500, height: 300)
            }
          }
        }
      }
    }
  }
`;

export default SinglePost;
