import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { getImage, GatsbyImage } from "gatsby-plugin-image";
import { RichText } from "prismic-reactjs";
import styled from "styled-components";

import { addGifToContent } from "../../utils";

const Content = styled.div`
  p {
    margin-bottom: 1.5rem;
  }
`;

const TextWithImage = ({ fluid, switchCols, image, content }) => {
  const img = getImage(image.localFile.childImageSharp);
  const serializer = addGifToContent();

  return (
    <Container fluid={fluid} className="mb-5 pb-5">
      <Row className="align-items-center">
        <Col lg={6} className={`mb-4 mb-lg-0 ${switchCols ? "order-1" : ""}`}>
          <GatsbyImage image={img} alt={image.alt || ""} />
        </Col>
        <Col lg={6} className={switchCols ? "order-0" : ""}>
          <Content>
            <RichText render={content} htmlSerializer={serializer} />
          </Content>
        </Col>
      </Row>
    </Container>
  );
};

export default TextWithImage;
