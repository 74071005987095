import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { getImage, GatsbyImage } from "gatsby-plugin-image";

const ImageColumns = ({ fluid, columns }) => {
  return (
    <Container fluid={fluid} className="mb-5 pb-5">
      <Row>
        {columns.map(column => {
          const img = getImage(column.image.localFile.childImageSharp);
          return (
            <Col
              lg={column.number_of_columns}
              key={column.image.localFile.childImageSharp.id}
              className="mb-4 mb-lg-0"
            >
              <GatsbyImage image={img} alt={column.image.alt || ""} />
            </Col>
          );
        })}
      </Row>
    </Container>
  );
};

export default ImageColumns;
