import React from "react";
import { Container } from "react-bootstrap";
import { RichText } from "prismic-reactjs";
import styled from "styled-components";

import { addGifToContent } from "../../utils";

const Wrapper = styled.div`
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    text-transform: uppercase;
    margin-bottom: 1.5rem;
  }
  p {
    margin-bottom: 1.2rem;
  }
`;

const Content = ({ fluid, content }) => {
  const serializer = addGifToContent();

  return (
    <Container fluid={fluid} className="mb-5 pb-5">
      <Wrapper>
        <RichText render={content} htmlSerializer={serializer} />
      </Wrapper>
    </Container>
  );
};

export default Content;
