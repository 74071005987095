import React from "react";
import { Container } from "react-bootstrap";
import { getImage, GatsbyImage } from "gatsby-plugin-image";

const FullWidthImage = ({ image, fluid }) => {
  const img = getImage(image.localFile.childImageSharp);

  return (
    <Container fluid={fluid} className="mb-5 pb-5">
      <GatsbyImage image={img} alt={image.alt || ""} />
    </Container>
  );
};

export default FullWidthImage;
