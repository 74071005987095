import React from "react";
import { Container, ResponsiveEmbed } from "react-bootstrap";

const VirtualTour = ({ fluid, url }) => {
  return (
    <Container fluid={fluid} className="mb-5 pb-5">
      <ResponsiveEmbed aspectRatio="16by9">
        <iframe
          src={url}
          width="100%"
          height="100%"
          frameborder="0"
          allowFullScreen="true"
          allow="fullscreen;accelerometer;gyroscope;magnetometer;vr;c<mer<;:microphone"
        />
      </ResponsiveEmbed>
    </Container>
  );
};

export default VirtualTour;
