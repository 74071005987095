import React from "react";
import { Container, ResponsiveEmbed } from "react-bootstrap";

const VideoBlock = ({ fluid, video }) => {
  const url = video.replace("watch?v=", "embed/");
  return (
    <Container fluid={fluid} className="mb-5 pb-5">
      <ResponsiveEmbed aspectRatio="16by9">
        <iframe src={url} />
      </ResponsiveEmbed>
    </Container>
  );
};

export default VideoBlock;
